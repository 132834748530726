.draftContent {
    padding-bottom: 2rem;
}

.newPageLine {
    border-bottom: 2px solid var(--background-element);
    position: relative;
    margin: 15px 0;
}
.newPageHover {
    position: absolute;
    width: 100%;
    height: 20px;
    top: -9px;
    cursor: pointer;
}
.newPageCircle {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 2px;
    border-radius: 50%;
    background-color: var(--background-element);
    transform: translateX(-50%);
    transition: all 0.1s ease;
}
.newPageCircle > svg {
    width: 16px;
    height: 16px;
    transition: all 0.1s ease;
}

.newPageHover:hover > .newPageCircle {
    width: 24px;
    height: 24px;
    top: -2px;
}
.newPageHover:hover > .newPageCircle > svg {
    width: 24px;
    height: 24px;
}

.newPageSelectorWrapper {
    display: flex;
    justify-content: center;
}
.newPageSelector {
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    background-color: var(--background-element);
    border-radius: 0.5rem;
    position: relative;
}
.selectorItem {
    padding: 0.5rem 1rem;
    min-width: 200px;
    cursor: pointer;
}
.selectorItem:hover, .selectorItem:focus {
    background-color: var(--background);
}

.newPageSelector::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 5px;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--background-element);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.publish {
    padding-bottom: 5rem;
}

.tagHeader {
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
}

.activityDue {
    color: var(--text);
}

.textFormattingCard {
    position: sticky;
    top: 60px;
}
