.iconLink {
    display: block;
    white-space: nowrap;
    color: var(--text-secondary);
    text-decoration: none;
    cursor: pointer;
}
.iconLink > svg {
    width: 1rem;
    height: 1rem;
    vertical-align: bottom;
}
.right > svg {
    margin-right: 0.2rem;
}
.left > svg {
    margin-left: 0.2rem;
}

.top {
    margin-top: 0.5rem;
}
.bottom {
    margin-bottom: 0.5rem;
}

.primary {
    color: var(--text-secondary-active);
    font-size: 1.1rem;
}
.primary > svg {
    width: 1.1rem;
    height: 1.1rem;
}

.iconLink:hover, .iconLink:focus {
    text-decoration: underline;
}
