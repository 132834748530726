.editToolbar {
    display: flex;
    margin-top: 0.5rem;
    padding: 0.2rem;
    background-color: var(--background-element);
    width: 100%;
    border-radius: 0.25rem;
}
.editToolbar.profile {
    width: initial;
    display: inline-flex;
    margin-top: 0;
    margin-left: 0.25rem;
    vertical-align: bottom;
}
.editToolbar > svg {
    padding: 0.3rem;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 0.25rem;
    margin-right: 0.1rem;
    height: 16px;
    width: 16px;
}
.editToolbar.profile > svg {
    font-size: 0.8rem;
    padding: 0.2rem;
    height: 13px;
    width: 13px;
}
.editToolbar > svg:last-child {
    margin-right: 0;
}
.editToolbar > svg:hover {
    background-color: var(--background-secondary);
}

.editToolbar > svg.iconEl {
    width: 25px;
    height: 25px;
    padding: 0.2rem;
}

.editToolbar > svg.active {
    color: var(--primary);
}

.editToolbar > .toolbarSep {
    margin-right: 0.5rem;
}
.editToolbar > .toolbarLeft {
    margin-left: auto;
}

.editable:hover {
    cursor: pointer;
}

/*#region TextPage*/
.textPage {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 0.75rem;
}
.textIcon {
    flex: 0 0 1.2rem;
    height: 1.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.2rem;
}
.textBlock {
    width: 100%;
    word-wrap: anywhere;
}
.textBig {
    font-size: 1.5rem;
}

.textPage.editable:hover {
    background-color: var(--background-element);
}

.textPage.editing {
    flex-direction: column;
}
.textPage.editing > textarea {
    font-size: 1rem;
    min-height: 10rem;
}
.textPage.editing > textarea.textBig {
    font-size: 1.25rem;
}
/*#endregion*/
/*#region LinkPage & QuotePage*/
.linkPage {
    display: flex;
    align-items: center;
    margin: 0.5rem 0.75rem;
    padding: 0.5rem;
    background-color: var(--background-element);
    color: var(--text);
    border-radius: 0.5rem;
    text-decoration: none;
}
.linkIcon {
    flex: 0 0 32px;
    margin-right: 0.5rem;
}
.linkText {
    flex-grow: 1;
    min-width: 0;
}
.linkName, .linkLink {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.linkName {
    font-weight: bold;
}
.linkLink {
    color: var(--text-secondary);
}
.linkPage:hover .linkLink {
    text-decoration: underline;
}

.quoteText {
    border-left: 0.2rem solid var(--background-secondary);
    padding-left: 0.5rem;
}

.linkPage.editable:hover {
    background-color: var(--background);
    cursor: pointer;
}
/*#endregion*/
/*#region ImagePage*/
.imagePage {
    position: relative;
    margin: 0.5rem 0;
    text-align: center;
}
.imagePage img {
    max-height: 512px;
}

/* --- editing --- */
.imagePage.editable:hover img {
    filter: opacity(50%);
}
.imagePage.editing {
    margin: 0.5rem;
}

/* -- wrapper and image -- */
.imagePageEditWrap {
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 100%;
    max-height: initial;
    position: relative;
}
.imagePageEditImage {
    max-width: 100%;
    min-height: 200px;
    border-radius: 0.5rem;
}
.imagePageEditPlaceholder {
    min-height: 200px;
    max-width: 100%;
    background-color: white;
    border-radius: 0.5rem;
}

/* -- overlay -- */
.imagePageEditOverlay {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
}

.imagePageEditWrap.dragOver > .imagePageEditOverlay {
    border: 5px dashed white;
}

.imagePageEditOverlay > svg {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
}

.imagePageEditDropOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    cursor: pointer;
}
/*#endregion*/
/*#region SpoilerPage*/
.spoilerHeader {
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.spoilerHeader.internal {
    padding: 0.5rem 0;
}

.spoilerIcon {
    width: 1.2rem;
    margin-right: 0.5rem;
    vertical-align: bottom;
}
.spoilerContent {
    position: absolute;
    top: -99999px;
    left: -99999px;
}
.spoilerContent.expanded {
    position: static;
}

.spoilerHeader.editable:hover {
    background-color: var(--background-element);
}
/*#endregion*/
/*#region ImagesPage*/
.imagesPage {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto auto auto;
}
.imagesImage {
    position: relative;
}
.imagesImage::before {
    content: "";
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
}

.imagesPage.editable:hover img {
    filter: opacity(50%);
}
/*#endregion*/
/*#region UserActivityPage*/
.activity {
    background-color: var(--background-element);
    border-radius: 0.5rem;
    margin: 1rem 0.75rem 0.5rem;
    padding: 0.5rem;
}
.activity.activityFull {
    background-color: var(--background-secondary);
    margin: 0 0 1rem;
    padding: 0.75rem;
}

.activitySection {
    display: flex;
    align-items: center;
}

.activityButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
.activityButtons > :last-child {
    margin-left: 0.5rem;
}

.activityDescription {
    margin: 0.5rem 0;
}
.activityNext {
    color: var(--text);
    text-decoration: none;
}
.activityNext:hover {
    text-decoration: underline;
}
/*#endregion*/
/*#region CodePage*/
.codePage {
    font-size: 0.8rem;
}
.codePage.editable:hover > pre {
    background-color: #555;
}
.codePageEditorCodeLabel {
    margin: 0 0.3rem;
}
.codePageEditorToolbar {
    margin: 0.5rem;
}
select.codePageEditorSelect {
    max-width: 200px;
    padding: 0.1rem;
    font-size: 1rem;
}
.codePageEditorInputWrap {
    margin: 0 0.5rem;
}
.codePageEditorInputWrap > textarea {
    font-family: var(--monospace-font);
    min-height: 300px;
}
/*#endregion*/
/*#region PollPage*/
.pollTitle {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}
.pollAllowed {
    color: var(--green);
}
.pollRestricted {
    color: var(--red);
}
.pollOption {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    /* todo: this background is pretty ugly, find something better */
    background: var(--background-secondary);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}
.pollVotable {
    cursor: pointer;
    transition: background 0.2s ease;
}
.pollVotable:hover {
    background: var(--background);
}
.pollRight {
    margin-left: auto;
}
.pollBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--green-very-muted);
    border-radius: 0.5rem;
    z-index: -1;
}
.pollOption > svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    vertical-align: bottom;
}
/*#endregion*/
/*#region VideoPage*/
.videoPage {
    position: relative;
}
.videoHidden {
    background: var(--background-element);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.videoPage::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16 / 9));
}
.videoPage > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoPageHeadline {
    font-weight: bold;
    font-size: 1.5rem;
}
.videoPageText {
    font-size: 1.1rem;
    padding: 1rem;
}
.left {
    margin-right: 0.5rem;
}

@media screen and (max-width: 500px) {
    .videoPageText {
        font-size: 1rem;
        padding: 0.5rem;
    }
}
/*#endregion*/
