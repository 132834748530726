.input {
    padding: 0.4rem;
    background-color: var(--background-element);
    color: var(--text);
    border: none;
    font-size: 1.2rem;
    width: 100%;
    border-radius: 0.25rem;
    outline: none;
}
.input:focus {
    outline: 2px solid var(--text-secondary);
}

.input[type=password], .input[data-password] {
    font-family: var(--monospace-font);
}
.input[type=checkbox] {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    margin-right: 0.5rem;
    outline: none;
}

.label {
    font-size: 1rem;
    width: 100%;
}
.label.horizontal {
    display: flex;
    align-items: center;
}
.label.block {
    display: block;
}

.label.inputMargin .input {
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

textarea.input {
    resize: vertical;
}

/* switch */

.switch {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 1.5rem;
}
.switchCheckbox {
    opacity: 0;
    width: 0;
    height: 0;
}
.switchSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-element);
    cursor: pointer;
    transition: all ease 0.4s;
    border-radius: 9999px;
}
.switchSlider::before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.25rem;
    top: 0.25rem;
    background-color: var(--text-secondary-active);
    border-radius: 50%;
    transition: transform ease 0.4s;
}

.switchCheckbox:checked + .switchSlider {
    background-color: var(--primary);
}
.switchCheckbox:checked + .switchSlider::before {
    transform: translateX(1.5rem);
}

.loading.switchSlider::before {
    opacity: 0;
}
.loadingSpinner {
    opacity: 0;
    position: absolute;
    height: 1rem;
    width: 1rem;
    left: 0.25rem;
    top: 0.25rem;
    fill: var(--text);
}

.loading.switchSlider > .loadingSpinner {
    opacity: 1;
}
.switchCheckbox:checked + .loading.switchSlider > .loadingSpinner {
    transform: translateX(1.5rem);
    /* overwrites the animation in Spinner.module.css */
    animation: 0.5s linear infinite spin-right;
}

@keyframes spin-right {
    from {
        transform: translateX(1.5rem) rotate(0deg);
    }
    to {
        transform: translateX(1.5rem) rotate(360deg);
    }
}
