.feed {
    display: flex;
    padding: 0;
}

.feedList {
    width: 660px;
    margin-bottom: 2rem;
}
.feedSidebar {
    flex: 0 0 330px;
    width: 330px;
    margin-left: 15px;
    margin-bottom: 2rem;
}
.feedStatic {
    display: none;
}

.loader {
    font-size: 1.5rem;
    padding: 1rem 1rem 2rem;
    text-align: center;
}
.loader svg {
    width: 1.5rem;
    vertical-align: bottom;
    margin-right: 0.5rem;
}

@media screen and (max-width: 930px) {
    .feedStatic {
        display: block;
    }
    .feedSidebar {
        display: none;
    }
    .feedList {
        width: 100%;
    }
}
