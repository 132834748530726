.tags {
    margin-bottom: 0.5rem;
}
@media screen and (max-width: 1005px) {
    .tags {
        padding: 0.5rem;
        margin-bottom: 0;
    }
}

.tag {
    border-radius: 1rem;
    background: var(--background-secondary);
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    height: 30px;
    display: inline-flex;
    vertical-align: bottom;
    color: var(--text);
    text-decoration: none;
    word-wrap: anywhere;
}
.tag.main {
    background: var(--background-element);
}
.tag.selectable {
    cursor: pointer;
}
.tag.selected {
    background: var(--orange-muted);
    color: var(--background);
}

.tagText {
    display: inline-block;
    padding: 0 0.5rem;
    line-height: 30px;
}
.tag.main .tagText {
    font-weight: bold;
}

.tagImage {
    border-radius: 50%;
}
