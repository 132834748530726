.cardTitle {
    padding: 0.75rem;
    font-weight: bold;
    border-bottom: 1px solid var(--background-element);
}
.cardContent {
    padding: 0.75rem;
}

.popularFandoms {
    list-style-type: none;
}
.popularFandom {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    text-decoration: none;
    color: var(--text);
}

.fandomTitleImg {
    padding: -10px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.fandomAvatar {
    margin-right: 10px;
    flex-shrink: 0;
}
.fandomName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.fandomSubs {
    white-space: nowrap;
    color: var(--text-secondary);
    text-decoration: none;
}
.fandomSubs {
    margin-left: 0.5rem;
}
.fandomSubs svg {
    width: 1rem;
    height: 1rem;
    vertical-align: bottom;
}
.fandomSubs svg {
    margin-right: 0.2rem;
}

.fandomSubs.left, .fandomSubs.right {
    margin-left: 0;
}
.fandomSubs.left svg {
    margin-right: 0.2rem;
}
.fandomSubs.right svg {
    margin-left: 0.2rem;
}

.authenticate {
    display: flex;
    text-align: center;
}
.registerButton {
    margin-right: 0.5rem;
}


.fandomIcon {
    margin-left: 0.25rem;
    width: 1rem;
    vertical-align: bottom;
}

.fandomButtons {
    margin: 0 0 0.5rem;
    text-align: center;
    padding: 0 0.75rem;
}
.fandomButtons > * {
    margin-bottom: 0.25rem;
}

.fandomDescription {
    overflow-wrap: break-word;
    padding: 0 0.75rem;
}

.fandomLinks {
    margin-top: 0.5rem;
    padding: 0 0.75rem;
}
.fandomProfileLinks {
    padding: 0.25rem 0.75rem;
}
.fandomLink {
    display: block;
    margin: 0.25rem 0;
    text-decoration: none;
}
.fandomProfileLink {
    margin: 0;
    padding: 0.25rem 0;
}

.fandomExpand {
    padding: 0.75rem;
}

.fandomLinkTitle {
    font-weight: bold;
    color: var(--text);
}
.fandomLinkUrl {
    color: var(--text-secondary);
}
.fandomLinkUrl > svg {
    width: 1rem;
    vertical-align: bottom;
    margin-right: 0.1rem;
}
.fandomLinkTitle, .fandomLinkUrl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.fandomLink:hover .fandomLinkUrl {
    text-decoration: underline;
}

.fandomHidden {
    display: none;
}
.fandomPrimary {
    color: var(--text);
    font-weight: bold;
}
.fandomSecondary {
    color: var(--text-secondary-active);
}
.fandomDivider {
    margin: 1rem 0.5rem 0.5rem;
}

.donatesSubtitle {
    margin-top: 0.25rem;
    text-align: right;
    color: var(--text-secondary);
}
.donatesCollected {
    color: var(--primary);
}
.donateButton {
    text-align: center;
    margin-top: 0.5rem;
}
.donateSum {
    color: var(--green);
    font-weight: bold;
}
.donateItem {
    padding: 0 0 0.5rem;
    display: flex;
    align-items: center;
    word-wrap: anywhere;
}
.donateHeader {
    display: flex;
}
.donateRight {
    margin-left: auto;
}

.rubricCof {
    margin-left: auto;
}
.rubricShare {
    margin-right: 0;
    margin-left: 0.5rem;
}

.feedTypeSelector {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}
.feedTypeSelectorItem {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-right: 0.5rem;
}
.feedTypeSelectorItem.selected {
    background: var(--background-element);
}
